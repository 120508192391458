import axios from "axios";

// const baseUrl = "https://api.4sightlabs.com/CP-Pro-API";

// const apiClient = axios.create({
//   baseURL: baseUrl,
//   withCredentials: true,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

export const setupAxiosInterceptors = (getAccessTokenSilently) => {
  axios.interceptors.request.use(
    async (config) => {
      // if (
      //   config.url.includes("/negotiate") ||
      //   config.url.includes("/getUserInfo?new=New") ||
      //   config.url.includes("/joinSignalRGroupsV2") ||
      //   config.url.includes("/removeSignalRGroupsV2") ||
      //   config.url.includes("/getEntityRouters")

      // )
      if (!config.url.includes("reports.4sightlabs.com"))
        try {
          const token = await getAccessTokenSilently();
          // console.log("INTERCEPTOR TOKEN", token);
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      return config;
    },
    (error) => Promise.reject(error)
  );
  // apiClient.interceptors.request.use(
  //   async (config) => {
  //     // if (
  //     //   config.url.includes("/negotiate") ||
  //     //   config.url.includes("/getUserInfo?new=New") ||
  //     //   config.url.includes("/joinSignalRGroupsV2") ||
  //     //   config.url.includes("/removeSignalRGroupsV2") ||
  //     //   config.url.includes("/getEntityRouters")
  //     // )
  //     try {
  //       const token = await getAccessTokenSilently();
  //       // console.log("INTERCEPTOR TOKEN", token);
  //       if (token) {
  //         config.headers.Authorization = `Bearer ${token}`;
  //       }
  //     } catch (error) {
  //       console.error("Error fetching access token:", error);
  //     }
  //     return config;
  //   },
  //   (error) => Promise.reject(error)
  // );
};

// export default apiClient;
