import React, { useState, useContext, useMemo, useEffect } from "react";
import ToastContext from "./ToastContext";
import { Spinner, Toast, ToastContainer } from "react-bootstrap";
import "./toast.css";
import userService from "../../Services/userService";
import UserContext from "../UserContext/UserContext";

import { useNavigate } from "react-router-dom";
import SubjectsContext from "../SubjectsContext/SubjectsContext";

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const { userData, accessToken, imageSasToken } = useContext(UserContext);
  const [startedResponding, setStartedResponding] = useState(false);
  const { subjects } = useContext(SubjectsContext);
  const navigate = useNavigate();
  const addToast = (
    title,
    category = "default",
    variant = "primary",
    documentInfo = null,
    setIsCriticalSoundPlaying = null,
    oldDocumentInfo = null,
    currentSound = null
  ) => {
    // Since content is hardcoded for "Critical", no need to pass it as a parameter
    setToasts((prevState) => [
      ...prevState,
      {
        id: `${Date.now()}-${Math.random()}`,
        title,
        category,
        variant,
        documentInfo,
        setIsCriticalSoundPlaying,
        oldDocumentInfo,
        currentSound,
      },
    ]);
  };

  const respondingButton = (subject, index) => {
    setStartedResponding(true);
    const payload = {
      entityId: userData?.userInfo?.entityId,
      subjectId: subject.id,
      action: "Responded",
      alertId: subject.alertid,
    };

    if (userData?.userInfo?.firstName) {
      payload.firstName = userData?.userInfo?.firstName;
    }

    if (userData?.userInfo?.lastName) {
      payload.lastName = userData?.userInfo?.lastName;
    }

    if (userData?.userInfo?.title) {
      payload.title = userData?.userInfo?.title;
    }

    userService
      .logUsersAlertAction(payload, accessToken)
      .then((response) => {
        console.log(response);
        removeToast(index);
        setStartedResponding(false);
      })
      .catch((error) => {
        console.log(error);
        setStartedResponding(false);
      });
  };

  useEffect(() => {
    const currentUserId = userData?.userInfo?.id;

    setToasts((prevToasts) => {
      let removedToast = null;
      const updatedToasts = prevToasts.filter((toast) => {
        if (toast.category !== "Critical") return true;

        // Find the subject using the documentInfo id from the toast
        const subject = subjects?.find((s) => s.id === toast.documentInfo?.id);
        if (!subject || subject.wellnessCategory !== "High") {
          console.log(
            "Removing toast because subject missing or not high wellness:",
            toast
          );
          removedToast = toast;
          return false;
        }

        // Now check if the subject has already responded
        if (subject?.officersRespondedToCritical) {
          const userHasResponded = subject.officersRespondedToCritical.some(
            (officer) => officer.user === currentUserId
          );
          if (userHasResponded) {
            console.log("User has already responded for subject:", subject.id);
            removedToast = toast;
            return false;
          }
        }

        return true;
      });

      const remainingCriticalToasts = updatedToasts.filter(
        (t) => t.category === "Critical" && t.currentSound
      );

      if (removedToast && remainingCriticalToasts.length === 0) {
        removedToast.currentSound?.stop();
        console.log("Last critical toast removed, sound stopped.");
      }

      return updatedToasts;
    });
  }, [subjects, userData]);

  const busyButton = (subject, index) => {
    setStartedResponding(true);
    console.log(subject);
    const payload = {
      entityId: userData?.userInfo?.entityId,
      subjectId: subject.id,
      action: "Busy",
      alertId: subject.alertid,
    };
    userService
      .logUsersAlertAction(payload, accessToken)
      .then((response) => {
        console.log(response);
        removeToast(index);
        setStartedResponding(false);
      })
      .catch((error) => {
        console.log(error);
        setStartedResponding(false);
      });
  };

  const removeToast = (id) => {
    setToasts((prevState) => {
      const toastToRemove = prevState.find((toast) => toast.id === id);
      const updatedToasts = prevState.filter((toast) => toast.id !== id);

      // Determine if the removed toast was critical
      const wasCritical = toastToRemove?.category === "Critical";

      // Count remaining critical toasts
      const remainingCriticalToasts = updatedToasts.filter(
        (t) => t.category === "Critical" && t.currentSound
      );

      // If it was critical and now no critical toasts remain, execute the callback
      if (wasCritical && remainingCriticalToasts.length === 0) {
        if (toastToRemove.setIsCriticalSoundPlaying !== null) {
          toastToRemove.setIsCriticalSoundPlaying(false);
        }
        toastToRemove.currentSound?.stop();
      }

      return updatedToasts;
    });
  };

  const contextValue = {
    addToast,
    removeToast,
  };
  const onDeviceToastCloseClicked = (e, id) => {
    e.stopPropagation();

    setToasts((prevToasts) => {
      const toastBeingClosed = prevToasts.find((toast) => toast.id === id);

      const updatedToasts = prevToasts.filter((toast) => toast.id !== id);

      if (toastBeingClosed && toastBeingClosed.category !== "Critical") {
        const nonCriticalRemaining = updatedToasts.filter(
          (t) =>
            t.category !== "Critical" &&
            t.documentInfo.priorityValue !== 36 &&
            t.documentInfo.priorityValue !== 195
        );

        if (nonCriticalRemaining.length === 0) {
          toastBeingClosed.currentSound?.stop();
        }
      }

      return updatedToasts;
    });
  };

  const subjectsToReopen = useMemo(() => {
    return subjects?.filter((subject) => {
      const officers = subject?.officersRespondedToCritical || [];
      const hasUserResponded = officers?.some(
        (officer) => officer?.user === userData?.userInfo?.id
      );
      return subject?.wellnessCategory === "High" && !hasUserResponded;
    });
  }, [subjects, userData]);

  const reopenCriticalAlerts = () => {
    // Check if subjects is an array
    if (subjectsToReopen?.length) {
      subjectsToReopen.forEach((subject) => {
        addToast("Critical Alert", "Critical", "danger", subject);
      });
      console.log(subjectsToReopen);
    }
  };

  const showOverlay = toasts.some((toast) => toast.category === "Critical");
  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {showOverlay && <div className="blur-overlay"></div>}
      <ToastContainer
        position="top-end"
        style={{ marginTop: 85, marginRight: 12 }}
      >
        {toasts.map((toast, index) => {
          const base64SvgIndex = userData?.entityAssets?.alertStates?.findIndex(
            (state) =>
              state.priorityValue === toast?.documentInfo?.priorityValue
          );

          const base64Svg =
            base64SvgIndex !== -1
              ? userData?.entityAssets?.alertStates[base64SvgIndex]?.wellnessSvg
              : userData?.userInfo?.darkTheme
                ? userData?.entityAssets?.alertStates[
                    userData?.entityAssets?.alertStates?.length - 1
                  ]?.darkThemeSvg
                : userData?.entityAssets?.alertStates[
                    userData?.entityAssets?.alertStates?.length - 1
                  ]?.lightThemeSvg;

          const svgURI = `data:image/svg+xml;base64,${base64Svg}`;
          const colorStyle =
            base64SvgIndex !== -1
              ? {
                  color:
                    userData?.entityAssets?.alertStates[base64SvgIndex]
                      ?.wellnessColor,
                }
              : userData?.userInfo?.darkTheme
                ? {
                    color:
                      userData?.entityAssets?.alertStates[
                        userData?.entityAssets?.alertStates?.length - 1
                      ]?.darkThemeCardColor,
                  }
                : {
                    color:
                      userData?.entityAssets?.alertStates[
                        userData?.entityAssets?.alertStates?.length - 1
                      ]?.lightThemeCardColor,
                  };
          const priorityDescription =
            base64SvgIndex !== -1
              ? userData?.entityAssets?.alertStates[base64SvgIndex]
                  ?.wellnessPriorityDescription
              : userData?.entityAssets?.alertStates[
                  userData?.entityAssets?.alertStates?.length - 1
                ]?.priorityDescription;
          const showBelowSubjectName = userData?.entityInformation
            ?.entityPreference?.showJmsId
            ? `ID: ${toast?.documentInfo?.jmsId || "---------"}`
            : userData?.entityInformation?.entityPreference?.showBookingId
              ? `ID: ${toast?.documentInfo?.bookingId || "---------"}`
              : "";

          const MAX_WIDTH = 20; // Define your maximum width here

          let truncatedLocation = "--";
          let lorawanTruncatedLocation = "--";

          if (toast?.documentInfo) {
            const { tier1Location, tier2Location, tier3Location } =
              toast?.documentInfo;

            if (tier1Location && tier2Location && tier3Location) {
              const fullLocation = `${tier1Location} - ${tier2Location} - ${tier3Location}`;
              if (fullLocation.length <= MAX_WIDTH) {
                truncatedLocation = fullLocation;
              } else if (
                `${tier2Location} - ${tier3Location}`.length <= MAX_WIDTH
              ) {
                truncatedLocation = `${tier2Location} - ${tier3Location}`;
              } else if (tier3Location.length <= MAX_WIDTH) {
                truncatedLocation = tier3Location;
              }
            } else if (tier1Location && tier2Location) {
              const twoTiersLocation = `${tier1Location} - ${tier2Location}`;
              if (twoTiersLocation.length <= MAX_WIDTH) {
                truncatedLocation = twoTiersLocation;
              } else if (tier2Location.length <= MAX_WIDTH) {
                truncatedLocation = tier2Location;
              }
            } else if (tier1Location && tier1Location.length <= MAX_WIDTH) {
              truncatedLocation = tier1Location;
            }
          }

          // ---------------------- LORAWAN location  ---------------------- //
          if (toast?.documentInfo?.loRaWANLastLocation) {
            const { tier1, tier2, tier3 } =
              toast?.documentInfo?.loRaWANLastLocation;

            if (tier1 && tier2 && tier3) {
              const fullLocation = `${tier1} - ${tier2} - ${tier3}`;
              if (fullLocation.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = fullLocation;
              } else if (`${tier2} - ${tier3}`.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = `${tier2} - ${tier3}`;
              } else if (tier3.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = tier3;
              }
            } else if (tier1 && tier2) {
              const twoTiersLocation = `${tier1} - ${tier2}`;
              if (twoTiersLocation.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = twoTiersLocation;
              } else if (tier2.length <= MAX_WIDTH) {
                lorawanTruncatedLocation = tier2;
              }
            } else if (tier1 && tier1?.length <= MAX_WIDTH) {
              lorawanTruncatedLocation = tier1;
            }
          }
          const truncatedLocationType =
            toast?.documentInfo?.connectionType === "lorawan"
              ? lorawanTruncatedLocation
              : truncatedLocation;
          const showJmsOrBookingId = userData?.entityInformation
            ?.entityPreference?.showJmsId
            ? toast?.documentInfo?.jmsId
              ? `ID: ${toast?.documentInfo?.jmsId}`
              : ""
            : userData?.entityInformation?.entityPreference?.showBookingId
              ? toast?.documentInfo?.bookingId
                ? `ID: ${toast?.documentInfo?.bookingId}`
                : ""
              : "";

          if (toast.category === "Critical") {
            // Special rendering for "Critical" category
            return (
              <Toast
                key={toast.id}
                // onClose={() => removeToast(index)}
                className={`critical-toast-card`} // Custom class for critical toast
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                autohide={false}
              >
                <div className={`critical-toast-card-top-section`}>
                  {/* <div className="d-flex"> */}
                  <img
                    src={
                      toast?.documentInfo?.imageUrl
                        ? `${toast?.documentInfo?.imageUrl}${imageSasToken}`
                        : toast?.documentInfo?.userId
                          ? "/svgs/Light_Checkout_Sensor_PFP.svg"
                          : toast?.documentInfo?.gender === "M"
                            ? userData?.userInfo?.darkTheme
                              ? "/svgs/MonitoredSubjectCard/user-circle.svg"
                              : "/svgs/MonitoredSubjectCard/user-circle.svg"
                            : userData?.userInfo?.darkTheme
                              ? "/svgs/MonitoredSubjectCard/user-circle.svg"
                              : "/svgs/MonitoredSubjectCard/user-circle.svg"
                    }
                    className={`critical-toast-card-profile-image`}
                    onError={(e) => {
                      if (toast?.documentInfo?.imageUrl) {
                        e.target.src =
                          toast?.documentInfo?.gender === "M"
                            ? "/svgs/MonitoredSubjectCard/user-circle.svg"
                            : "/svgs/MonitoredSubjectCard/user-circle.svg";

                        e.target.className =
                          "critical-toast-card-profile-image";
                      }
                    }}
                  />
                  {/* <div>
                    <img
                      src={svgURI}
                      alt="priority description image"
                      className="critical-toast-card-priority-svg"
                    />
                  </div> */}
                </div>
                <div className={`critical-toast-information-section`}>
                  <div className="critical-toast-information-name">
                    {toast?.documentInfo?.firstName &&
                    toast?.documentInfo?.lastName
                      ? `${toast?.documentInfo?.firstName} ${toast?.documentInfo?.lastName}`
                      : toast?.documentInfo?.firstName
                        ? toast?.documentInfo?.firstName
                        : toast?.documentInfo?.lastName
                          ? toast?.documentInfo?.lastName
                          : "--"}
                  </div>
                  <div className="critical-toast-information-jms-booking-id">
                    {showJmsOrBookingId}
                  </div>
                  <div className="critical-toast-information-description-location-container">
                    <div
                      className="critical-toast-information-description"
                      style={colorStyle}
                    >
                      {priorityDescription}
                    </div>
                    <div className="critical-toast-information-location-container">
                      <div className="critical-toast-information-location-attribute">{`Location: `}</div>
                      <div className="critical-toast-information-truncated-location">{` ${truncatedLocationType}`}</div>
                    </div>
                  </div>
                </div>
                {/* </div> */}

                <div className={`critical-toast-card-bottom-section`}>
                  {!startedResponding ? (
                    <React.Fragment>
                      {((userData?.entityInformation?.criticalAlertButtons &&
                        userData?.entityInformation?.criticalAlertButtons?.includes(
                          "Occupied"
                        )) ||
                        !userData?.entityInformation?.criticalAlertButtons) && (
                        <div
                          onClick={() => {
                            busyButton(toast.documentInfo, toast.id);
                          }}
                          className={`critical-toast-card-buttons critical-toast-card-occupied-button`}
                        >
                          Occupied
                        </div>
                      )}

                      {((userData?.entityInformation?.criticalAlertButtons &&
                        userData?.entityInformation?.criticalAlertButtons?.includes(
                          "Responding"
                        )) ||
                        !userData?.entityInformation?.criticalAlertButtons) && (
                        <div
                          onClick={() => {
                            respondingButton(toast.documentInfo, toast.id);
                          }}
                          className={`critical-toast-card-buttons critical-toast-card-responding-button`}
                        >
                          Responding
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <div className="d-flex text-center align-items-center justify-content-center w-100">
                      <Spinner />
                    </div>
                  )}
                </div>
              </Toast>
            );
          } else {
            // Default rendering for non-critical toasts
            return (
              <Toast
                key={toast.id}
                onClose={() => removeToast(toast.id)}
                autohide
                delay={
                  userData?.entityInformation?.alertPreferences?.popupsDelay ||
                  5000
                }
                className={`status-change-container`}
                style={{ zIndex: 1000, cursor: "pointer" }}
                onClick={(e) => {
                  navigate(
                    `/user/dashboard/subject/${toast?.documentInfo?.id}`
                  );
                  onDeviceToastCloseClicked(e, toast.id);
                }}
              >
                {/* <div className="d-flex"> */}
                <img
                  src={
                    toast?.documentInfo?.imageUrl
                      ? `${toast?.documentInfo?.imageUrl}${imageSasToken}`
                      : toast?.documentInfo?.userId
                        ? "/svgs/Light_Checkout_Sensor_PFP.svg"
                        : toast?.documentInfo?.gender === "M"
                          ? userData?.userInfo?.darkTheme
                            ? "/svgs/MonitoredSubjectCard/user-circle.svg"
                            : "/svgs/MonitoredSubjectCard/user-circle.svg"
                          : userData?.userInfo?.darkTheme
                            ? "/svgs/MonitoredSubjectCard/user-circle.svg"
                            : "/svgs/MonitoredSubjectCard/user-circle.svg"
                  }
                  className={`status-change-toast-image`}
                  onError={(e) => {
                    if (toast?.documentInfo?.imageUrl) {
                      e.target.src =
                        toast?.documentInfo?.gender === "M"
                          ? "/svgs/MonitoredSubjectCard/user-circle.svg"
                          : "/svgs/MonitoredSubjectCard/user-circle.svg";

                      e.target.className = "status-change-toast-image";
                    }
                  }}
                />
                {/* <img
                  src={"/svgs/MonitoredSubjectCard/Vector 277.svg"}
                  className={"toast-subject-card-dashed-lines"}
                />
                <img
                  src={"/svgs/MonitoredSubjectCard/Vector 275.svg"}
                  className={"toast-subject-card-dashed-lines-mini"}
                /> */}
                <div className="toast-subject-card-information-container">
                  <div className="toast-subject-card-name">
                    {" "}
                    {toast?.documentInfo?.firstName &&
                    toast?.documentInfo?.lastName
                      ? `${toast?.documentInfo?.firstName} ${toast?.documentInfo?.lastName}`
                      : toast?.documentInfo?.firstName
                        ? toast?.documentInfo?.firstName
                        : toast?.documentInfo?.lastName
                          ? toast?.documentInfo?.lastName
                          : "--"}
                  </div>
                  <div className={"toast-subject-card-id"}>
                    {showBelowSubjectName}
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-end gap-2">
                  <div className="toast-subject-card-status" style={colorStyle}>
                    {priorityDescription}
                  </div>
                  <img
                    src="/svgs/Modal/x-close.svg"
                    alt="close"
                    onClick={(e) => onDeviceToastCloseClicked(e, toast.id)}
                  />
                </div>
              </Toast>
            );
          }
        })}
      </ToastContainer>
      {subjectsToReopen?.length > 0 &&
        userData?.userInfo?.userRole !== "4SightAdmin" && (
          <div
            className="toast-critical-realert-container"
            onClick={reopenCriticalAlerts}
          >
            <div className="toast-critical-realert-circle-container">
              {/* <img
                src="/svgs/Toasts/alert-circle.svg"
                alt="alertcircle"
                className="toast-critical-realert-image"
              /> */}
              {subjectsToReopen?.length}
            </div>
          </div>
        )}
    </ToastContext.Provider>
  );
};
