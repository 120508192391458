import React, { useEffect, useContext, useState, useCallback } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from "./Contexts/UserContext/UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from "./Components/Loading";
import userService from "./Services/userService";
import { SignalRProvider } from "./Contexts/SignalRContext/SignalRProvider";
import { SuperSignalRProvider } from "./Contexts/SignalRContext/SuperUser/SuperSignalRProvider";
import { SubjectsProvider } from "./Contexts/SubjectsContext/SubjectsProvider";
import { RoutersProvider } from "./Contexts/RoutersContext/RoutersProvider";
import { ToastProvider } from "./Contexts/ToastContext/ToastProvider";
import { useLocation } from "react-router-dom"; // Assuming you use react-router-dom
import PrivacyPolicyRouteComponent from "./Routes/PrivacyPolicyRouteComponent";
import ShareRouteComponent from "./Routes/ShareRouteComponent";
import NoAccessRouteComponent from "./Routes/NoAccessRouteComponent";
import { setupAxiosInterceptors } from "./Services/apiClient";

// Lazy-loaded route components
const SuperUserRouteComponent = React.lazy(
  () => import("./Routes/SuperUserRouteComponent")
);
const UserRouteComponent = React.lazy(
  () => import("./Routes/UserRouteComponent")
);

function App() {
  const { userData, setUserData, setAccessToken } = useContext(UserContext);
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    error,
    logout,
    loginWithRedirect,
  } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    setupAxiosInterceptors(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const currentPath = location.pathname;
    const isDataShareRoute = currentPath.startsWith("/data-share/");
    const isPrivacyPolicyRoute = currentPath.startsWith("/privacypolicy");
    const isAccessDenied = currentPath.startsWith("/?error=access_denied");

    if (
      !isLoading &&
      !isAuthenticated &&
      !isDataShareRoute &&
      !isPrivacyPolicyRoute &&
      !isAccessDenied
    ) {
      if (currentPath !== "/")
        sessionStorage.setItem("redirectPath", currentPath);
    }
  }, [isLoading, isAuthenticated, location.pathname]);

  useEffect(() => {
    if (userData?.userInfo?.darkTheme) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, [userData?.userInfo?.darkTheme]);

  const fetchAccessToken = useCallback(() => {
    getAccessTokenSilently()
      .then((response) => {
        setAccessToken(response);
      })
      .catch((err) => {
        console.log("silent error", err.message);
        const currentPath = location.pathname;

        const isAccessDenied = currentPath.startsWith("/?error=access_denied");

        if (!isAccessDenied) {
          const storedEmail = localStorage.getItem("user_email");

          loginWithRedirect({
            authorizationParams: { login_hint: storedEmail || "" },
          });
        }
      });
  }, [getAccessTokenSilently, setAccessToken]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isPrivacyPolicy = currentPath.startsWith("/privacypolicy");
    if (!isLoading && !isPrivacyPolicy) fetchAccessToken();
  }, [fetchAccessToken, isLoading]);

  useEffect(() => {
    if (!isAuthenticated) return;

    const intervalTime = 60 * 60 * 1000;
    const interval = setInterval(() => {
      console.log("FETCHING");
      getAccessTokenSilently({ cacheMode: "off" })
        .then((response) => {
          setAccessToken(response);
        })
        .catch((err) => {
          console.log("silent error", err.message);
        });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isAuthenticated, getAccessTokenSilently, setAccessToken]);

  // Fetch user data after login
  useEffect(() => {
    if (isAuthenticated) {
      userService
        .getUserInfo()
        .then((response) => {
          setUserData(response.data);
          if (response.data?.userInfo?.email) {
            localStorage.setItem("user_email", response.data.userInfo.email);
          }
        })
        .catch((error) => {
          if (
            error.response.data === "User's info is not found" &&
            error.response.status === 404
          ) {
            setUserData("User Not Found");
          }
        });
    }
  }, [isAuthenticated, setUserData]);

  if (error) {
    if (
      error.message.includes("Access denied") ||
      error.message.includes("domain restriction") ||
      error.message === "Access denied. IP address not allowed."
    ) {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
          federated: true,
        },
      });
    }
    return <div>{error?.message || "oops. something went wrong"}</div>;
  }

  // Render Privacy Policy and Share Routes without authentication
  if (location.pathname.startsWith("/privacypolicy"))
    return <PrivacyPolicyRouteComponent />;
  if (location.pathname.startsWith("/data-share/"))
    return <ShareRouteComponent />;

  // Handle user not found
  if (isAuthenticated && userData === "User Not Found")
    return <NoAccessRouteComponent />;

  // Show loading state
  if (
    isLoading ||
    !isAuthenticated ||
    !userData ||
    // !accessToken ||
    !userData?.userInfo?.userRole
  )
    return <Loading />;

  // Role-based rendering for authenticated users
  const role = userData?.userInfo?.userRole;
  return (
    <RoutersProvider>
      <SubjectsProvider>
        <ToastProvider>
          <React.Suspense fallback={<Loading />}>
            {role === "4SightAdmin" ? (
              <SuperSignalRProvider>
                <SuperUserRouteComponent />
              </SuperSignalRProvider>
            ) : role ? (
              <SignalRProvider>
                <UserRouteComponent />
              </SignalRProvider>
            ) : (
              <div>Oops... Something Went Wrong</div>
            )}
          </React.Suspense>
        </ToastProvider>
      </SubjectsProvider>
    </RoutersProvider>
  );
}

export default App;
